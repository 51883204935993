import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { Button, Input, InputAdornment, Theme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Education, GuidanceModule, Module } from '../../../types';
import NotesDialog from '../../notes/NotesDialog';
import GuidanceAccordion from './GuidanceAccordion';
import columnConfig from '../columnConfig';
import AppContext from '../../../AppContext';
import Loader from '../../../components/Loader';
import EducationRepository from '../EducationRepository';

interface GuidanceProps {
  education: Education;
}

const useStyles = makeStyles((theme: Theme) => ({
  panelHeader: {
    display: 'flex',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  column: {
    fontWeight: theme.typography.fontWeightBold,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  rows: {
    width: '100%',
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  nameColumn: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: `calc(100% 
                      - 20%
                      - ${columnConfig.assignmentsColumn.minWidth}px
                      - ${columnConfig.statusColumn.minWidth}px
                      - ${columnConfig.startDateColumn.minWidth}px
                      + 40px
               )`,
    wordBreak: 'break-word',
  },
  assignmentsColumn: {
    width: 'auto',
  },
}));

const Guidance = (props: GuidanceProps) => {
  const { education } = props;
  const classes = useStyles();
  const { localStore } = useContext(AppContext);
  const [allModules, setAllModules] = useState<GuidanceModule[] | undefined>(
    undefined,
  );
  const [shownModules, setShownModules] = useState<GuidanceModule[]>([]);
  const [expandedModules, setExpandedModules] = useState<string[]>([]);
  const [notesOpen, setNotesOpen] = useState<boolean>(false);

  /**
   * Search
   */
  const doSearch = _.debounce((query: string) => {
    if (!allModules) {
      return;
    }

    if (query.length === 0) {
      setShownModules(allModules);
      return;
    }

    setShownModules(
      allModules
        .map((module) => {
          const filteredAssignments = module.assignments.filter((assignment) =>
            assignment.name.toLowerCase().includes(query.toLowerCase()),
          );

          return { ...module, assignments: filteredAssignments };
        })
        .filter((module) => module.assignments.length > 0),
    );
  }, 500);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    doSearch(query);
  };

  const handleOpenNotes = () => setNotesOpen(true);
  const handleCloseNotes = () => setNotesOpen(false);

  const handlePanelChange = (module: Module, expanded: boolean) => {
    let newExpandedModules;

    if (expanded) {
      newExpandedModules = [...expandedModules, module.id];
    } else {
      newExpandedModules = expandedModules.filter((id) => module.id !== id);
    }

    setExpandedModules(newExpandedModules);
  };

  useEffect(() => {
    localStore.setItem<string[]>(
      `${education.id}_guidance_expanded_modules`,
      expandedModules,
    );
  }, [localStore, education.id, expandedModules]);

  useEffect(() => {
    localStore
      .getItem<string[]>(`${education.id}_guidance_expanded_modules`)
      .then((value) => {
        if (value) {
          setExpandedModules(value);
        }
      });

    localStore
      .getItem<number>(`${education.id})_guidance_y_offset`)
      .then((value) => {
        if (value) {
          window.scrollTo({
            top: value,
            left: 0,
            behavior: 'smooth',
          });
        }
      });

    const persistScrollPosition = () => {
      localStore.setItem(
        `${education.id})_guidance_y_offset`,
        window.pageYOffset,
      );
    };

    window.addEventListener('scroll', persistScrollPosition, true);

    return () =>
      window.removeEventListener('scroll', persistScrollPosition, true);
  }, [localStore, education.id]);

  const loadModules = useCallback(() => {
    new EducationRepository()
      .getModules(education.id, ['BEG'])
      .then((response) => {
        const modules = (response.data as GuidanceModule[]).sort((a, b) =>
          a.publishedName.localeCompare(b.publishedName),
        );
        setAllModules(modules);
      });
  }, [education.id]);

  // create an alias for semantic reasons
  const handleRefresh = loadModules;

  useEffect(loadModules, [loadModules]);

  useEffect(() => {
    if (allModules) {
      setShownModules(allModules);
    }
  }, [allModules]);

  useEffect(() => {
    setExpandedModules((prev) =>
      shownModules.map((module) => module.id).filter((id) => prev.includes(id)),
    );
  }, [shownModules]);

  if (!allModules) {
    return <Loader />;
  }

  const adornment = (
    <InputAdornment position="start">
      <FontAwesomeIcon icon={['fal', 'search']} />
    </InputAdornment>
  );

  return (
    <div>
      <NotesDialog
        education={education}
        open={notesOpen}
        onClose={handleCloseNotes}
      />
      <div className={classes.searchContainer}>
        <Button
          startIcon={<FontAwesomeIcon icon={['fal', 'sticky-note']} />}
          onClick={handleOpenNotes}
          variant="contained"
          color="primary"
        >
          Logboek
        </Button>
        <Input
          type="text"
          onChange={handleSearch}
          data-testid="search-box"
          startAdornment={adornment}
          placeholder="Zoeken..."
        />
      </div>
      <div className={classes.panelHeader}>
        <div className={`${classes.column} ${classes.nameColumn}`}>Naam</div>
        <div className={`${classes.column} ${classes.assignmentsColumn}`}>
          Opdrachten
        </div>
      </div>
      {shownModules.map((module) => (
        <GuidanceAccordion
          key={module.id}
          module={module}
          expanded={expandedModules.includes(module.id)}
          onChange={handlePanelChange}
          onRefresh={handleRefresh}
        />
      ))}
    </div>
  );
};

export default Guidance;
