import React, { useCallback, useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import ProfessionalActivityPanel from './ProfessionalActivityPanel';
import ProfessionalActivityDialog from './ProfessionalActivityDialog';
import { Education, EPAModule, Module } from '../../../types';
import Loader from '../../../components/Loader';
import AppContext from '../../../AppContext';
import EducationRepository from '../EducationRepository';

interface ProfessionalActivities {
  education: Education;
}

const useStyles = makeStyles((theme: Theme) => ({
  panelHeader: {
    display: 'flex',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  column: {
    fontWeight: theme.typography.fontWeightBold,
  },
  nameColumn: {
    width: '100%',
    maxWidth: 'calc(100% - 245px - 110px)',
  },
  levelColumn: {
    position: 'relative',
    display: 'flex',
    width: 250,
    minWidth: 250,
  },
}));

const ProfessionalActivities = (props: ProfessionalActivities) => {
  const { education } = props;
  const classes = useStyles();

  const [modules, setModules] = useState<EPAModule[] | undefined>(undefined);
  const [expandedModules, setExpandedModules] = useState<string[]>([]);
  const { localStore } = useContext(AppContext);
  const [state, setState] = useState<{
    formModule: EPAModule | null;
    dialogOpen: boolean;
  }>({
    formModule: null,
    dialogOpen: false,
  });

  const { formModule, dialogOpen } = state;

  const sortModules = (modules: Module[]) => {
    return modules.sort((a, b) => {
      const aNum = a.publishedName.match(/[^\d]*(\d*).*/);
      const bNum = b.publishedName.match(/[^\d]*(\d*).*/);

      if (!aNum || !bNum) {
        return 0;
      }

      return parseInt(aNum[1], 10) > parseInt(bNum[1], 10) ? 1 : -1;
    });
  };

  const loadModules = useCallback(() => {
    new EducationRepository()
      .getModules(education.id, ['EPA'])
      .then((response) => {
        const modules = sortModules(response.data) as EPAModule[];
        setModules(modules);
      });
  }, [education.id]);

  const handlePanelChange = (module: EPAModule, expanded: boolean) => {
    let newExpandedModules;

    if (expanded) {
      newExpandedModules = [...expandedModules, module.id];
    } else {
      newExpandedModules = expandedModules.filter((id) => module.id !== id);
    }

    setExpandedModules(newExpandedModules);
  };

  const setFormModule = (module: EPAModule) => {
    setState({ ...state, formModule: module, dialogOpen: true });
  };

  const handleClose = () => {
    setState({ ...state, formModule: null, dialogOpen: false });
  };

  const handleSubmit = () => {
    handleClose();
    loadModules();
  };

  useEffect(() => {
    loadModules();
  }, [loadModules]);

  useEffect(() => {
    if (modules === undefined) {
      return;
    }

    setExpandedModules((prev) =>
      modules.map((module) => module.id).filter((id) => prev.includes(id)),
    );
  }, [modules]);

  useEffect(() => {
    localStore.setItem<string[]>(
      `${education.id}_epa_expanded_modules`,
      expandedModules,
    );
  }, [localStore, education.id, expandedModules]);

  useEffect(() => {
    localStore
      .getItem<string[]>(`${education.id}_epa_expanded_modules`)
      .then((value) => {
        if (value) {
          setExpandedModules(value);
        }
      });

    localStore
      .getItem<number>(`${education.id})_epa_y_offset`)
      .then((value) => {
        if (value) {
          window.scrollTo({
            top: value,
            left: 0,
            behavior: 'smooth',
          });
        }
      });

    const persistScrollPosition = () => {
      localStore.setItem(`${education.id})_epa_y_offset`, window.pageYOffset);
    };

    window.addEventListener('scroll', persistScrollPosition, true);

    return () =>
      window.removeEventListener('scroll', persistScrollPosition, true);
  }, [localStore, education.id]);

  if (!modules) {
    return <Loader />;
  }

  return (
    <div>
      {formModule && (
        <ProfessionalActivityDialog
          module={formModule}
          onClose={handleClose}
          onSubmit={handleSubmit}
          open={dialogOpen}
        />
      )}
      <div className={classes.panelHeader}>
        <div className={`${classes.column} ${classes.nameColumn}`}>Naam</div>
        <div className={`${classes.column} ${classes.levelColumn}`}>
          Niveau behaald
        </div>
      </div>
      {modules
        .sort((a, b) => a.publishedName.localeCompare(b.publishedName))
        .map((module) => (
          <ProfessionalActivityPanel
            key={module.id}
            module={module}
            setFormActivity={setFormModule}
            expanded={expandedModules.includes(module.id)}
            onChange={handlePanelChange}
          />
        ))}
    </div>
  );
};

export default ProfessionalActivities;
