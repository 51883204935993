import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import AppContext from '../../../AppContext';
import Loader from '../../../components/Loader';
import { Education, Module, PracticalModule } from '../../../types';
import columnConfig from '../columnConfig';
import { filterModulesByName, sortModulesByColumn } from '../EducationHelpers';
import EducationRepository from '../EducationRepository';
import TabPanelHeadSortable from '../TabPanelHeadSortable';
import PracticalModuleAccordion from './PracticalModuleAccordion';
import DataControls from '../DataControls';

const useStyles = makeStyles((theme: Theme) => ({
  modulePanel: {
    marginBottom: theme.spacing(2),
  },
  sortable: {
    display: 'flex',
    width: '100%',
    fontSize: 17,
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
  },
  column: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: theme.typography.fontWeightBold,
  },
  nameColumn: columnConfig.nameColumn,
}));

type SortableColumn = 'publishedName';

interface Ordering {
  order: 'ASC' | 'DESC';
  orderBy: SortableColumn;
}

interface PracticalEducationProps {
  education: Education;
}

const PracticalEducation = (props: PracticalEducationProps) => {
  const classes = useStyles();
  const { localStore } = useContext(AppContext);

  const [allModules, setAllModules] = useState<Module[] | undefined>(undefined);
  const [shownModules, setShownModules] = useState<Module[]>([]);

  const [ordering, setOrdering] = useState<Ordering>({
    order: 'ASC',
    orderBy: 'publishedName',
  });

  const handleSortBy = (column: SortableColumn) => {
    setOrdering((prev) => ({
      orderBy: column,
      order: prev.order === 'ASC' ? 'DESC' : 'ASC',
    }));
  };

  const [hideFinishedModules, setHideFinishedModules] =
    useState<boolean>(false);
  const [expandedModules, setExpandedModules] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const handlePanelChange = (module: Module, expanded: boolean) => {
    setExpandedModules((prev) => {
      if (expanded) {
        return [...prev, module.id];
      }
      return prev.filter((id: string) => module.id !== id);
    });
  };

  const toggleHideFinishedModules = () => {
    setHideFinishedModules((prev) => !prev);
  };

  const toggleMinimize = useCallback(() => {
    setExpandedModules((prev) =>
      prev.length > 0 ? [] : shownModules.map((m) => m.id),
    );
  }, [shownModules]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value as string);
  };

  const filterModules = useCallback(
    (modules: Module[]) => {
      let filteredModules = [...modules];

      if (hideFinishedModules) {
        filteredModules = filteredModules.filter(
          (module: Module) => !module.finished,
        );
      }

      if (searchTerm.length >= 1) {
        filteredModules = filterModulesByName(modules, searchTerm);
      }

      filteredModules = sortModulesByColumn(
        filteredModules,
        ordering.orderBy,
        ordering.order,
      );

      return filteredModules;
    },
    [hideFinishedModules, searchTerm, ordering],
  );

  const loadModules = useCallback(() => {
    new EducationRepository()
      .getPracticalModules(props.education.id)
      .then((response) => setAllModules(response.data));
  }, [props.education.id]);

  // create an alias for semantic reasons
  const handleRefresh = loadModules;

  useEffect(loadModules, [loadModules]);

  useEffect(() => {
    if (allModules) {
      setShownModules(filterModules(allModules));
    }
  }, [allModules, filterModules]);

  useEffect(() => {
    setExpandedModules((prev) =>
      shownModules.map((module) => module.id).filter((id) => prev.includes(id)),
    );
  }, [shownModules]);

  useEffect(() => {
    localStore.setItem('hide_finished_modules', hideFinishedModules);
  }, [localStore, hideFinishedModules]);

  useEffect(() => {
    localStore.getItem<boolean>('hide_finished_modules').then((value) => {
      if (value === null) {
        return;
      }

      setHideFinishedModules(value);
    });
  }, [localStore]);

  useEffect(() => {
    localStore.setItem('expanded_modules', expandedModules);
  }, [localStore, expandedModules]);

  useEffect(() => {
    localStore.getItem<string[]>('expanded_modules').then((value) => {
      if (value === null) {
        return;
      }

      setExpandedModules(value);
    });
  }, [localStore]);

  if (!allModules) {
    return <Loader />;
  }

  return (
    <>
      <Box mb={5}>
        <DataControls
          hideFinishedModules={hideFinishedModules}
          minimal={expandedModules.length === 0}
          handleHideFinishedModules={toggleHideFinishedModules}
          handleMinimize={toggleMinimize}
          handleSearch={handleSearch}
          searchTerm={searchTerm}
        />
      </Box>
      <Box className={classes.sortable}>
        <TabPanelHeadSortable
          className={`${classes.column} ${classes.nameColumn}`}
          label="Naam"
          handleSortBy={handleSortBy}
          sortBy="publishedName"
          order={ordering.order}
          orderBy={ordering.orderBy}
        />
      </Box>
      <Box>
        {shownModules.map((module: Module) => (
          <PracticalModuleAccordion
            key={module.id}
            className={classes.modulePanel}
            module={module as PracticalModule}
            onChange={handlePanelChange}
            onRefresh={handleRefresh}
            expanded={expandedModules.includes(module.id)}
            searchTerm={searchTerm}
          />
        ))}
      </Box>
    </>
  );
};

export default PracticalEducation;
