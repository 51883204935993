import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useSnackbar } from 'notistack';
import { AssignmentContainer, Module, PracticalModule } from '../../../types';
import { colors } from '../../../config/theme';
import StyledAccordion from '../../../components/StyledAccordion';
import ContainedAssignmentRow from '../assignment/ContainedAssignmentRow';
import AssignmentRepository from '../assignment/repository/AssignmentRepository';
import { refreshAccount } from '../../../actions';
import EducationContext from '../EducationContext';
import { sortAssignments } from '../assignment/helpers';
import FormPreviewIcon from '../../forms/FormPreviewIcon';
import DebugPopover from '../../../components/DebugPopover';
import statusLabels from '../statusLabels';

interface PracticalModuleAccordionProps {
  module: PracticalModule;
  className?: string;
  onChange: (module: Module, expanded: boolean) => void;
  onRefresh: () => void;
  expanded: boolean;
  searchTerm: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  summary: {
    '&[aria-expanded="true"]': {
      '& $iconClass': {
        color: theme.palette.primary.contrastText,
      },
    },
  },
  iconClass: {
    color: theme.palette.primary.dark,
  },
  summaryRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  rows: {
    width: '100%',
  },
  nameColumn: {
    width: '100%',
    wordBreak: 'break-word',
  },
  containerHeader: {
    display: 'flex',
    width: '100%',
    padding: theme.spacing(2),
    background: '#FFF',
  },
  containerHeaderNameColumn: {
    width: '100%',
    maxWidth: `calc(60% - ${theme.spacing(2)}px)`,
    marginRight: theme.spacing(2),
  },
  containerHeaderAssignmentsColumn: {
    width: '100%',
    maxWidth: `calc(25% - ${theme.spacing(2)}px)`,
    marginRight: theme.spacing(2),
  },
  containerHeaderOptionsColumn: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    maxWidth: `calc(15% - ${theme.spacing(2)}px)`,
  },
  assignmentContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  assignmentContainerHeader: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(2),
    background: colors.tertiary.ultraLight,
    fontWeight: theme.typography.fontWeightBold,
    '&:hover': {
      '& $removeButton': {
        opacity: 1,
      },
    },
  },
  assignmentContainerContent: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  containerCard: {
    padding: theme.spacing(2),
    width: '90%',
    marginBottom: theme.spacing(2),
    background: colors.primary.ultraLight,
    transition: theme.transitions.create('all'),
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.light,
    },
  },
  optionIcon: {
    color: theme.palette.primary.dark,
  },
}));

const PracticalModuleAccordion = (props: PracticalModuleAccordionProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notifications = useSnackbar();
  const { module, className, onChange, onRefresh, searchTerm } = props;
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [locked, setLocked] = useState<boolean>(false);
  const [debugPopoverEl, setDebugPopoverEl] =
    useState<HTMLButtonElement | null>(null);
  const [debugContainer, setDebugContainer] =
    useState<AssignmentContainer | null>(null);
  const [debugContainerPopoverEl, setDebugContainerPopoverEl] =
    useState<HTMLButtonElement | null>(null);
  const { educationPermissions } = useContext(EducationContext);

  const handleChange = () => {
    if (module.assignmentContainers.length > 0) {
      onChange(module, !props.expanded);
    }
  };

  const handleDialogOpen = (event: React.MouseEvent) => {
    event.stopPropagation();
    setDialogOpen(true);
  };

  const handleDialogClose = () => setDialogOpen(false);

  const handleCreateAssignmentFromContainer = (
    container: AssignmentContainer,
  ) => {
    setLocked(true);

    new AssignmentRepository()
      .createFromContainer(container)
      .then(() => {
        dispatch(refreshAccount());
        notifications.enqueueSnackbar('De opdracht is aangemaakt.', {
          variant: 'success',
        });
      })
      .catch(() => {
        notifications.enqueueSnackbar('Fout bij aanmaken opdracht.', {
          variant: 'error',
        });
      })
      .finally(() => {
        setLocked(false);
        onRefresh();
        handleDialogClose();
      });
  };

  /**
   * Debug popover
   */
  const handleDebugOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setDebugPopoverEl(e.currentTarget);
  };
  const handleDebugClose = () => setDebugPopoverEl(null);

  const handleDebugContainerOpen = (
    e: React.MouseEvent<HTMLButtonElement>,
    container: AssignmentContainer,
  ) => {
    e.stopPropagation();
    setDebugContainer(container);
    setDebugContainerPopoverEl(e.currentTarget);
  };
  const handleDebugContainerClose = () => {
    setDebugContainer(null);
    setDebugContainerPopoverEl(null);
  };

  const summary = (
    <div className={classes.summaryRow}>
      <div className={classes.nameColumn}>{module.publishedName}</div>
      {educationPermissions.imitatingAdmin && (
        <Box mr={1}>
          <IconButton size="small" onClick={handleDebugOpen}>
            <FontAwesomeIcon icon={['fal', 'bug']} />
          </IconButton>
        </Box>
      )}
      {educationPermissions.edit &&
        module.assignmentContainers.filter((c) => c.form).length > 0 && (
          <IconButton
            onClick={handleDialogOpen}
            size="small"
            className={classes.iconClass}
            disabled={locked}
          >
            <FontAwesomeIcon icon={['fal', 'plus-circle']} />
          </IconButton>
        )}
    </div>
  );

  return (
    <div className={className}>
      <DebugPopover
        id="module"
        anchorEl={debugPopoverEl}
        onClose={handleDebugClose}
        properties={[
          { name: 'ID', value: module.id },
          { name: 'AFAS ID', value: module.afasId },
          { name: 'Module ID', value: module.moduleId },
          { name: 'Type', value: module.type },
        ]}
      />

      {debugContainer && (
        <DebugPopover
          id="container"
          anchorEl={debugContainerPopoverEl}
          onClose={handleDebugContainerClose}
          properties={[
            { name: 'ID', value: debugContainer.id },
            { name: 'AFAS ID', value: debugContainer.afasId },
            { name: 'Blauwdruk ID', value: debugContainer.blueprintId },
            { name: 'Template ID', value: debugContainer.form.id },
          ]}
        />
      )}

      <Dialog maxWidth="xs" open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle id="confirmation-dialog-title">
          Nieuwe opdracht aanmaken
        </DialogTitle>
        <DialogContent>
          {module.assignmentContainers
            .filter((c) => c.form !== null)
            .map((container) => (
              <Paper
                className={classes.containerCard}
                onClick={() => handleCreateAssignmentFromContainer(container)}
                key={`assignment-container-select-${container.id}`}
              >
                {container.name}
              </Paper>
            ))}
        </DialogContent>
      </Dialog>

      <StyledAccordion
        id={`module-${module.id}-panel-content`}
        summary={summary}
        summaryClassName={classes.summary}
        onChange={handleChange}
        expanded={props.expanded}
        expandable={module.assignmentContainers.length > 0}
        noPadding
        chevron
      >
        <div className={classes.rows}>
          <div className={classes.containerHeader}>
            <div className={classes.containerHeaderNameColumn}>Naam</div>
            <div className={classes.containerHeaderAssignmentsColumn}>
              Opdrachten
            </div>
          </div>
          {module.assignmentContainers
            .sort((a, b) => a.name.localeCompare(b.name))
            .filter((container) => {
              if (!searchTerm) {
                return true;
              }

              return (
                container.name
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase()) ||
                container.assignments.find((a) =>
                  a.name.toLowerCase().includes(searchTerm.toLowerCase()),
                )
              );
            })
            .map((container) => {
              return (
                <div
                  className={classes.assignmentContainer}
                  key={`assignment-container-${container.id}`}
                >
                  <div className={classes.assignmentContainerHeader}>
                    <div className={classes.containerHeaderNameColumn}>
                      {container.name}
                    </div>
                    <div className={classes.containerHeaderAssignmentsColumn}>
                      {container.status === 'excemption'
                        ? statusLabels[container.status]
                        : `${
                            container.assignments.filter((a) => a.finished)
                              .length
                          } / ${container.requiredNumberOfAssignments}`}
                    </div>
                    <Box className={classes.containerHeaderOptionsColumn}>
                      {educationPermissions.imitatingAdmin && (
                        <Box mr={1}>
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              return handleDebugContainerOpen(e, container);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={['fal', 'bug']}
                              className={classes.optionIcon}
                            />
                          </IconButton>
                        </Box>
                      )}
                      {container.form && (
                        <FormPreviewIcon formId={container.form.id} />
                      )}
                    </Box>
                  </div>
                  <div className={classes.assignmentContainerContent}>
                    {sortAssignments(container.assignments).map(
                      (assignment) => (
                        <ContainedAssignmentRow
                          assignment={assignment}
                          key={`contained-assignment-row-${assignment.id}`}
                          onRefresh={onRefresh}
                        />
                      ),
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </StyledAccordion>
    </div>
  );
};

export default PracticalModuleAccordion;
