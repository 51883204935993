import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItemText,
  Popover,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { green } from '@material-ui/core/colors';

import { Assignment, EducationModule, Event, Module } from '../../../types';
import AssignmentRow from '../assignment/AssignmentRow';
import columnConfig from '../columnConfig';
import StyledAccordion from '../../../components/StyledAccordion';
import StatusFlagIcon from '../StatusFlagIcon';
import { sortAssignments } from '../assignment/helpers';
import EducationContext from '../EducationContext';
import ModuleTypes from './ModuleTypes';
import DebugPopover from '../../../components/DebugPopover';
import EventRegisterButton from './EventRegisterButton';
import EducationEducatorContext from '../EducationEducatorContext';
import PresenceItemRow from './PresenceItemRow';

interface ModuleAccordionProps {
  module: EducationModule;
  className?: string;
  expanded?: boolean;
  onChange?: (module: Module, expanded: boolean) => void;
  variant: 'education' | 'further-education';
  onEventWizardSelection: (module: Module) => void;
  onWorkshopsWizardSelection: (module: Module, event: Event) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  summary: {
    paddingLeft: 0,
  },
  rows: {
    width: '100%',
  },
  nameColumn: {
    ...columnConfig.nameColumn,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'top',
    wordBreak: 'break-word',
  },
  furtherEducationNameColumn: {
    ...columnConfig.furtherEducation.nameColumn,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'top',
    wordBreak: 'break-word',
  },
  typeColumn: columnConfig.typeColumn,
  assignmentsColumn: columnConfig.assignmentsColumn,
  furtherEducationPointsColumn: columnConfig.furtherEducation.pointsColumn,
  statusColumn: columnConfig.statusColumn,
  presenceColumn: columnConfig.presenceColumn,
  startDateColumn: columnConfig.startDateColumn,
  iconColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '20%',
  },
  iconInactive: {
    color: theme.palette.primary.dark,
    opacity: 0.3,
    '&.MuiIconButton-root.Mui-disabled': {
      color: theme.palette.primary.dark,
    },
  },
  chevron: {
    transition: theme.transitions.create('transform'),
  },
  chevronGrid: {
    marginLeft: theme.spacing(1),
  },
  finishedIcon: {
    color: green[200],
    marginLeft: theme.spacing(2),
  },
  infoIcon: {
    display: 'block',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  infoHeader: {
    fontWeight: 700,
    fontSize: 17,
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  infoBody: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
}));

const ModuleAccordion = (props: ModuleAccordionProps) => {
  const classes = useStyles();
  const {
    module,
    className,
    onChange,
    variant,
    onEventWizardSelection,
    onWorkshopsWizardSelection,
  } = props;
  const { education, educationPermissions } = useContext(EducationContext);
  const { educatorEducation, setEducatorView } = useContext(
    EducationEducatorContext,
  );
  const [state, setState] = useState<{
    finishedAssignments: number | null;
    totalAssignments: number | null;
    startDate: Date | null;
    hasSchedule: boolean | null;
    moduleEvent: Event | null;
    infoAnchorEl: HTMLDivElement | null;
    debugAnchorEl: HTMLDivElement | null;
  }>({
    finishedAssignments: null,
    totalAssignments: null,
    startDate: null,
    hasSchedule: null,
    moduleEvent: null,
    infoAnchorEl: null,
    debugAnchorEl: null,
  });

  const {
    finishedAssignments,
    totalAssignments,
    startDate,
    moduleEvent,
    infoAnchorEl,
    debugAnchorEl,
  } = state;

  const isExpandable =
    (module.assignments && module.assignments.length > 0) ||
    (module.presenceItems && module.presenceItems.length > 0);

  const moodleUrl = useMemo(() => {
    if (!education?.id || !moduleEvent || !moduleEvent.moodleCourseId) {
      return null;
    }

    return `/onderwijs/${education.id}/evenement/${moduleEvent.id}/leermateriaal`;
  }, [moduleEvent, education]);

  const populateState = useCallback(() => {
    // only consider events that are linked to the current education
    const educationEvents = module.events.filter(
      (event) => event.educationId === education?.afasId,
    );
    const event = educationEvents.length >= 1 ? educationEvents[0] : null;

    setState((prev) => ({
      ...prev,
      finishedAssignments: module.assignments!.filter((a) => a.finished).length,
      totalAssignments:
        module.assignments!.length > 0 ? module.assignments!.length : null,
      moduleEvent: event,
      startDate: event ? new Date(event.startDate) : null,
    }));
  }, [module.events, module.assignments, education]);

  const sortedAssignments: Assignment[] = useMemo(() => {
    return module.assignments ? sortAssignments(module.assignments) : [];
  }, [module.assignments]);

  useEffect(() => {
    populateState();
  }, [populateState]);

  const handleChange = () => {
    if (onChange) {
      onChange(module, !props.expanded);
    }
  };

  /**
   * Info popover
   */
  const handleInfoOpen = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();

    if (moduleEvent) {
      setState({ ...state, infoAnchorEl: e.currentTarget });
    }
  };

  const handleInfoClose = () => setState({ ...state, infoAnchorEl: null });

  /**
   * Debug popover
   */
  const handleDebugOpen = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setState({ ...state, debugAnchorEl: e.currentTarget });
  };

  const handleDebugClose = () => setState({ ...state, debugAnchorEl: null });

  const handleAgendaClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (module.events.length === 0 || educatorEducation) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (educatorEducation && setEducatorView) {
      setEducatorView('agenda');
    }
  };

  const handleEventRegisterWizardOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    onEventWizardSelection(module);
  };

  const computeStartDateColumnContents = (
    module: Module,
    event: Event | null,
  ): ReactNode => {
    if (module.elearning) {
      return '-';
    }

    if (startDate) {
      const readableStartDate = moment(startDate).format('DD-MM-YYYY');

      if (
        event &&
        event?.workshops &&
        event?.workshopsEditableUntil &&
        moment(new Date()).format('YYYYMMDD') <
          moment(event.workshopsEditableUntil).format('YYYYMMDD')
      ) {
        return (
          <Box display="flex" alignItems="center">
            <Box mr={1}>{readableStartDate}</Box>
            <Tooltip title="Workshops kiezen/wijzigen">
              <Box>
                <IconButton
                  onClick={() => onWorkshopsWizardSelection(module, event)}
                  size="small"
                >
                  <FontAwesomeIcon
                    icon={['fal', 'chalkboard-teacher']}
                    style={{ width: 20, height: 20, padding: 0 }}
                  />
                </IconButton>
              </Box>
            </Tooltip>
          </Box>
        );
      }

      return readableStartDate;
    }

    if (module.eventRegistrationAllowed) {
      return <EventRegisterButton onClick={handleEventRegisterWizardOpen} />;
    }

    return '-';
  };

  const showMissedEducationIcon =
    module.presenceItems &&
    module.presenceItems.length >= 1 &&
    !module.finished;

  const summary = (
    <>
      <div
        className={
          variant === 'further-education'
            ? classes.furtherEducationNameColumn
            : classes.nameColumn
        }
      >
        {showMissedEducationIcon ? (
          <>
            <Box pr={1}>{module.publishedName}</Box>
            <Tooltip title="Compenseren van verzuim">
              <Box>
                <FontAwesomeIcon
                  icon={['fal', 'history']}
                  style={{
                    width: 20,
                    height: 20,
                  }}
                />
              </Box>
            </Tooltip>
          </>
        ) : (
          module.publishedName
        )}
      </div>
      {!education?.furtherEducation && (
        <div className={classes.typeColumn}>
          <Tooltip title={ModuleTypes[module.type]}>
            <span>{module.type}</span>
          </Tooltip>
        </div>
      )}
      <div className={classes.assignmentsColumn}>
        {finishedAssignments !== null && totalAssignments !== null && (
          <span>{`${finishedAssignments} / ${totalAssignments}`}</span>
        )}
      </div>
      {variant === 'further-education' && (
        <div className={classes.furtherEducationPointsColumn}>
          {moduleEvent ? moduleEvent.points : ''}
        </div>
      )}
      <div className={classes.statusColumn}>
        <StatusFlagIcon module={module} />
      </div>
      <div className={classes.startDateColumn}>
        {computeStartDateColumnContents(module, moduleEvent)}
      </div>
      <Grid container spacing={2} className={classes.iconColumn}>
        {educationPermissions.imitatingAdmin && (
          <Grid
            item
            onClick={handleDebugOpen}
            aria-owns={debugAnchorEl ? `${module.id}-debug-popover` : undefined}
            aria-haspopup="true"
          >
            <IconButton size="small">
              <FontAwesomeIcon icon={['fal', 'bug']} />
            </IconButton>
          </Grid>
        )}
        <Grid
          item
          className={classes.infoIcon}
          onClick={handleInfoOpen}
          aria-owns={infoAnchorEl ? `${module.id}-info-popover` : undefined}
          aria-haspopup="true"
        >
          <IconButton size="small" disabled={!moduleEvent}>
            <FontAwesomeIcon
              icon={['fal', 'info-circle']}
              className={moduleEvent ? '' : classes.iconInactive}
            />
          </IconButton>
        </Grid>
        <Tooltip title="Agenda">
          <Grid item>
            <IconButton
              onClick={handleAgendaClick}
              component={Link}
              to="/agenda"
              size="small"
            >
              <FontAwesomeIcon
                icon={['fal', 'calendar-alt']}
                className={module.events.length > 0 ? '' : classes.iconInactive}
              />
            </IconButton>
          </Grid>
        </Tooltip>
        <Tooltip title="Leermateriaal">
          <Grid item>
            <IconButton
              component={Link}
              disabled={!moodleUrl}
              to={moodleUrl || '#'}
              className={moodleUrl ? '' : classes.iconInactive}
              size="small"
              target="_blank"
            >
              <FontAwesomeIcon icon={['fal', 'books']} />
            </IconButton>
          </Grid>
        </Tooltip>
      </Grid>
    </>
  );

  return (
    <div className={className}>
      <Popover
        id={`${module.id}-info-popover`}
        open={Boolean(infoAnchorEl)}
        onClose={handleInfoClose}
        anchorEl={infoAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
      >
        <Typography variant="h6" component="h3" className={classes.infoHeader}>
          Informatie
        </Typography>
        {moduleEvent && (
          <Typography variant="body1" className={classes.infoBody}>
            <List>
              <ListItemText>
                NSPOH opleider:
                <Box ml={1} display="inline">
                  {moduleEvent.educator}
                  <Box ml={1} display="inline">
                    (
                    <a href={`mailto:${moduleEvent.educatorEmail}`}>
                      {moduleEvent.educatorEmail}
                    </a>
                    )
                  </Box>
                </Box>
              </ListItemText>
              <ListItemText>
                Programma assistent:
                <Box ml={1} display="inline">
                  {moduleEvent.programAssistant}
                  <Box ml={1} display="inline">
                    (
                    <a href={`mailto:${moduleEvent.programAssistantEmail}`}>
                      {moduleEvent.programAssistantEmail}
                    </a>
                    )
                  </Box>
                </Box>
              </ListItemText>
              {moduleEvent.points && (
                <ListItemText>
                  {`Contacturen: ${moduleEvent.points}`}
                </ListItemText>
              )}
            </List>
          </Typography>
        )}
      </Popover>
      <DebugPopover
        id="module"
        anchorEl={debugAnchorEl}
        onClose={handleDebugClose}
        properties={[
          { name: 'ID', value: module.id },
          { name: 'AFAS ID', value: module.afasId },
          { name: 'Module ID', value: module.moduleId },
          { name: 'Type', value: module.type },
        ]}
      />
      <StyledAccordion
        id={`module-${module.id}-panel-content`}
        summary={summary}
        summaryClassName={classes.summary}
        onChange={handleChange}
        expanded={props.expanded}
        expandable={isExpandable}
        chevron
      >
        <div className={classes.rows}>
          {module.presenceItems &&
            module.presenceItems.map((presenceItem) => (
              <PresenceItemRow
                key={presenceItem.sessionId}
                presenceItem={presenceItem}
              />
            ))}
          {sortedAssignments.map((assignment) => (
            <AssignmentRow
              key={assignment.id}
              assignment={assignment}
              variant={variant}
            />
          ))}
        </div>
      </StyledAccordion>
    </div>
  );
};

export default ModuleAccordion;
